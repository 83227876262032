<template>
  <product :title="title"
    :swiper-data="swiperData"
    :slogan="slogan"
    :price="price"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://j.youzan.com/YOl_5Y"
    is-bus-city
  ></product>
</template>

<script>
import { CDN_URL, RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'stratos-3-starwar',
  components: {
    Product
  },
  data () {
    return {
      title: 'Amazfit 智能运动手表 3 星球大战系列限量版',
      slogan: '独占红白表圈 | 专属定制表带 | 专属定制表盘<br> 定制 1.34 英寸全圆反射屏 | 四星三模精准GPS | 80 种运动模式<br/> FIRSTBEAT 专业运动分析 | Ultra / 智能双模式 | 最高 14 天长续航<br>  70 小时 GPS 续航 | 百城公交卡 | NFC 模拟门卡（仅支持中国大陆）',
      price: 1699,
      colors: [
        '星战版'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/stratos-3-starwar/swiper/1.png',
          CDN_URL + '/images/product/stratos-3-starwar/swiper/2.png',
          CDN_URL + '/images/product/stratos-3-starwar/swiper/3.png',
          CDN_URL + '/images/product/stratos-3-starwar/swiper/4.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        CDN_URL + '/images/product/stratos-3-starwar/detail/s01.jpg',
        CDN_URL + '/images/product/stratos-3-starwar/detail/s02.jpg',
        CDN_URL + '/images/product/stratos-3-starwar/detail/s03.jpg',
        CDN_URL + '/images/product/stratos-3-starwar/detail/s04.jpg',
        CDN_URL + '/images/product/stratos-3-starwar/detail/s05.jpg',
        CDN_URL + '/images/product/stratos-3-starwar/detail/s06.jpg',
        CDN_URL + '/images/product/stratos-3-starwar/detail/s07.jpg',
        CDN_URL + '/images/product/stratos-3-starwar/detail/s08.jpg',
        CDN_URL + '/images/product/stratos-3-starwar/detail/s09.jpg',
        CDN_URL + '/images/product/stratos-3-starwar/detail/s10.jpg',
        CDN_URL + '/images/product/stratos-3-starwar/detail/s11.jpg',
        CDN_URL + '/images/product/stratos-3-starwar/detail/s12.jpg',
        CDN_URL + '/images/product/stratos-3-starwar/detail/s13.jpg',
        CDN_URL + '/images/product/stratos-3-starwar/detail/s14.jpg',
        CDN_URL + '/images/common/zepp-black.jpg',
        CDN_URL + '/images/product/stratos-3-starwar/detail/s15.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/stratos-3-starwar/detail/s16.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
